const SETTINGS = {
  CAPTCHA_SITE_KEY: process.env.GATSBY_CAPTCHA_SITE_KEY,
  FALLBACK_CAPTCHA_SITE_KEY: process.env.GATSBY_FALLBACK_CAPTCHA_SITE_KEY,
  SINGPASS_AUTH_URL: process.env.GATSBY_SINGPASS,
  SINGPASS_SCRIPT: process.env.GATSBY_SINGPASS_SCRIPT,
  SHOW_FALLBACK_CAPTCHA: process.env.GATSBY_SHOW_FALLBACK_CAPTCHA,
  // SINGPASS_PROD: "https://id.singpass.gov.sg/static/ndi_embedded_auth.js",
  SINGPASS_CLIENT_ID: process.env.GATSBY_SINGPASS_CLIENT_ID,
  SINGPASS_SUCCESS_CALLBACK: 'sso/singpass',

  GC_LEARN_MORE: 'https://www.singtel.com/personal/support/onepass',
  GE_LERAN_MORE: `${process.env.GATSBY_SM_GE}/page/static/faq/`,
  SM_GC: process.env.GATSBY_SM_GC,
  SM_GE: process.env.GATSBY_SM_GE,
  GC_SM_TARGET: `${process.env.GATSBY_SM_GC}/siteminderagent/forms/login.fcc`,
  GE_SM_TARGET: `${process.env.GATSBY_SM_GE}/siteminderagent/forms/login.fcc`,

  GC_CREATE_ACCOUNT: `${process.env.GATSBY_SM_GC}/ssoselfservice/flowtoRegisterAccountPage.action?portalTy`,
  GE_CREATE_ACCOUNT: `${process.env.GATSBY_SM_GE}/page/static/companySelfRegistration/`,

  GC_FORGOT_PWD: `${process.env.GATSBY_SM_GC}/ssoselfservice/flowToForgotPassword.action`,
  GC_FORGOT_PWD_ACTION: `${process.env.GATSBY_SM_GC}/ssoselfservice/flowToForgotPassword.action?exceedTimes=true`,
  GE_FORGOT_PWD: `${process.env.GATSBY_SM_GE}/page/login/?type=forgotpass`,
  UDF_FORGOT_PWD: `${process.env.GATSBY_SM_GE}/page/static/forgotpasswordlink/?FLOWID=UDF`,
  LANDING_PAGE: process.env.GATSBY_LANDING_PAGE,
  MAINTENANCEBANNER: process.env.GATSBY_MAINTENANCEBANNER,
  MAINTENANCEBANNERMESSAGE: process.env.GATSBY_MAINTENANCEBANNERMESSAGE,
  MAX_CAPTCHA_ATTEMPTS: process.env.GATSBY_MAX_CAPTCHA_ATTEMPTS || 5
};

export default SETTINGS;
